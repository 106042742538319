<template>
  <div :key="$route.path">
    <!-- {{ offersEntries }} -->
    <!-- Summe aller Angebote:
    {{ this.$store.getters.getTotalSumOfAllStoragesAndOffers }} -->
    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Zeige</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>Einträge</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="filter"
                class="d-inline-block mr-1"
                placeholder="Suche..."
                type="search"
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <!-- Offer Table -->
      <b-table
        ref="refListTable"
        striped
        hover
        :current-page="currentPage"
        :per-page="perPage"
        :items="offersEntries"
        :fields="fields"
        :filter="filter"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        responsive
        show-empty
        empty-text="Keine Einträge gefunden"
        @row-clicked="onRowClicked"
      >
        <template #cell(storagesSum)="data">
          <b-badge variant="success">
            {{ sumStoragePrice(data.item) }} €
          </b-badge>
        </template>
        <template #cell(isActive)="data">
          <b-badge v-if="data.item.isActive" variant="success"> Ja </b-badge>
          <b-badge v-else variant="danger"> Nein </b-badge>
        </template>
        <template #cell(created_at)="data">
          {{ getFormattedDate(data.item.created_at) }}
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Zeige {{ dataMeta.from }} bis {{ dataMeta.to }} von
              {{ dataMeta.of }} Einträge</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="getTotalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BCol,
  BRow,
  BBadge,
  BFormInput,
  BPagination,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import helper from '@/helper/helper'
import vSelect from 'vue-select'
import tableHelper from '../../Table+Helper'

export default {
  setup() {
    const { getFormattedDate } = helper()
    const {
      perPageOptions,
      perPage,
      filter,
      totalRows,
      currentPage,
      refListTable,
      dataMeta,
      refetchData,
    } = tableHelper()

    return {
      getFormattedDate,
      perPageOptions,
      filter,
      perPage,
      totalRows,
      currentPage,
      refListTable,
      dataMeta,
      refetchData,
    }
  },
  components: {
    BCard,
    BTable,
    BCol,
    BRow,
    BBadge,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormInput,
    vSelect,
  },
  data() {
    return {
      sortBy: 'id',
      sortDesc: true,
      fields: [
        {
          key: 'id',
          label: 'ID',
          sortable: true,
        },
        {
          key: 'isActive',
          label: 'Aktiv',
          sortable: true,
        },
        {
          key: 'created_at',
          label: 'Erstellt',
          sortable: true,
        },
        {
          key: 'firstName',
          label: 'Vorname',
          sortable: false,
        },
        {
          key: 'lastName',
          label: 'Nachname',
          sortable: false,
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true,
        },
        {
          key: 'phoneNumber',
          label: 'Telefon',
          sortable: true,
        },
        {
          key: 'storages.length',
          label: '# Lager',
          sortable: true,
        },
        // sum storage price
        {
          key: 'storagesSum',
          label: 'Angebotshöhe',
          sortable: true,
        },
      ],
    }
  },
  computed: {
    offersEntries() {
      return this.$store.getters.offerEntries
    },
    getTotalRows() {
      return this.offersEntries.length
    },
  },

  updated() {
    this.totalRows = this.getTotalRows
  },

  methods: {
    onRowClicked(row) {
      this.$router.push({
        name: 'offers-view',
        params: { id: row.id },
      })
    },
    // sum storage price
    sumStoragePrice(item) {
      let sum = 0
      item.storages.forEach(storage => {
        // console.log(storage)
        sum += storage.price
      })
      return sum
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
